import { createBrowserHistory,  } from 'history';
import ReactGA from "react-ga4";

export const history = createBrowserHistory();

ReactGA.initialize("G-KCGLT4867S"
  // "G-KCGLT4867S"
  , 
{
  testMode: true,
}
);

history.listen((location, action) => {
    console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
    console.log(`The last navigation action was ${action}`)
    ReactGA.send({ hitType: "pageview", page: location.pathname });

    // ReactGA.set({ page: location.pathname });
    // ReactGA.pageview(location.pathname);
  })

  
// export const history = createHashHistory({        
//     basename: '',             // The base URL of the app (see below)
//     hashType: 'slash',        // The hash type to use (see below)
//     // A function to use to confirm navigation with the user (see below)
//     getUserConfirmation: (message, callback) => callback(window.confirm(message))
// })

