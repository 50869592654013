export const config = {

    // Communication with LiveNyte API
    // apiUrl: 'http://10.0.64.20/lv_develop/index.php',
    // apiUrl: 'http://10.8.0.1/lv_develop/index.php',
    apiUrl: 'https://demo.livenyte.com/demo.php',
    // apiUrl: 'https://livenyte.com/index.php',

    // Browser Web address for main LiveNyte website. 
    // serverUrl: 'http://10.0.64.20:3002',
    // serverUrl: 'http://10.8.0.1:3002',
    serverUrl: 'https://demo.livenyte.com',
    // serverUrl: 'https://livenyte.com',

    //The common URL
    // commonUrl: 'http://10.0.64.20/lv_develop',
    // commonUrl: 'http://10.8.0.1/lv_develop',
    commonUrl: 'https://demo.livenyte.com',
    // commonUrl: 'https://livenyte.com',


    // Browser Web address for LiveNyte Creator Websites. 
    // creatorPortal: 'http://10.0.64.20:3001',
    // creatorPortal: 'http://10.8.0.1:3001',
    creatorPortal: 'https://democreators.livenyte.com',
    // creatorPortal: 'https://creators.livenyte.com',

    shortCreatorLink: 'https://nyte.live',

    // Stripe Connect URL for express payout account creation
    connectUrl: "https://connect.stripe.com/express/oauth/authorize?redirect_uri=",

    // Stripe ConnectID for stripe API connect accounts
    // connectId: 'ca_CTGuLipbdcfPE22mbVR6vyvQqb62n3Fp',
    connectId: 'ca_CTGuZ31k7CU7TB2PkbggYw5x2xBzCjEa', // test

    // Stripe ProviderK for stripe charge requests
    // providerK: 'pk_live_38wkdi3itXw9aLx2SolZ3DfX',
    providerK: 'pk_test_ZldHStGJfmHqNDeoCzn61aiI', //test

    // Default google coordinates when location is not provided by user 
    // defaultLocation: {lat: 40.7127753, lng: -74.0059728, subAdminArea: 'New York'}, // New York,
    defaultLocation: {lat: 37.7749295, lng: -122.41941550000001, subAdminArea: 'San Francisco'}, // San Francisco,
    // defaultLocation: {lat: 34.0522342, lng: -118.2436849, subAdminArea: 'Los Angeles'}, // Los Angeles

    GOOGLE_API_KEY: 'AIzaSyCITDHmKKTK8fYi1occWyHBKM9n4JN73xk',


    //links
    privacyLocalPath: "/Common/privacy.html",
    termsLocalPath: "/Common/terms.html"
}
